<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6" align-self="end">
          <h3 class="title text-capitalize">{{ $t("accounting settings") }}</h3>
        </v-col>
      </v-row>
      <v-divider class="mb-4"></v-divider>
      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-1">
                  <v-icon left>mdi-scales</v-icon>{{ $t("taxation") }}(%)
                </div>

                <v-list-item-subtitle>
                  {{ $t("set your Taxation rate on earnings") }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="taxation"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        :rules="rules.emptyField"
                        @keypress="digits"
                      ></v-text-field>
                    </template>
                    <span>{{
                      $t("if not set, it will be calculated at 0%")
                    }}</span>
                  </v-tooltip></v-list-item-subtitle
                >
                <v-btn
                  small
                  dark
                  class="gradients"
                  :color="$store.state.secondaryColor"
                  @click="savePaybill"
                  :loading="taxationSaveLoading"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize font-weight-bold">{{
                    $t("save")
                  }}</span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-1">
                  <v-icon left>mdi-scales</v-icon>{{ $t("dividends") }}(%)
                </div>

                <v-list-item-subtitle>
                  {{ $t("set your dividends rate on earnings") }}

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dividends"
                        dense
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.emptyField"
                        @keypress="digits"
                      ></v-text-field>
                    </template>
                    <span>{{
                      $t("if not set, it will be calculated at 0%")
                    }}</span>
                  </v-tooltip>
                </v-list-item-subtitle>
                <v-btn
                  small
                  dark
                  class="gradients"
                  :color="$store.state.secondaryColor"
                  @click="saveDividends"
                  :loading="dividendsSaveLoading"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize font-weight-bold">{{
                    $t("save")
                  }}</span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
      </v-layout>

      <v-snackbar
        v-model="snackSuccessTaxation"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("taxation percentage number updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessTaxation = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorTaxation"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{
          $t("could not update taxation percentage. Try again later")
        }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorTaxation = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackSuccessDividends"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("dividends percentage number updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessDividends = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorDividends"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{
          $t("could not update dividends percentage. Try again later")
        }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorDividends = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackErrorLoading"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not fetch data. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorLoading = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackLoading"
        top
        center
        color="info lighten-5 info--text text--darken-3"
        :timeout="12000"
      >
        <span>{{ $t("loading ...") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="info darken-3"
            @click="snackLoading = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import db from "@/plugins/fb";
import { digits } from "@/plugins/functions";
export default {
  data() {
    return {
      taxation: "",
      dividends: "",
      snackError: false,
      snackSuccess: false,
      snackLoading: true,
      snackErrorLoading: false,
      buttonLoading: false,
      snackSuccessTaxation: false,
      snackErrorTaxation: false,
      snackErrorDividends: false,
      snackSuccessDividends: false,
      paybill: "",
      taxationSaveLoading: false,
      dividendsSaveLoading: false,
    };
  },
  created() {
    this.currentUser = this.$store.state.currentUser;
    this.getSettings();
  },
  computed: {
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },
  methods: {
    digits,
    getSettings() {
      this.snackLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.taxation = snaps.data().taxation;
            this.dividends = snaps.data().dividends;
            this.snackLoading = false;
          } else {
            this.taxation = 0;
            this.dividends = 0;
            this.snackLoading = false;
          }
        })
        .catch(() => {
          this.snackErrorLoading = true;
        });
      // ######
    },
    savePaybill() {
      this.taxationSaveLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          taxation: this.taxation,
        })
        .then(() => {
          this.taxationSaveLoading = false;
          this.snackSuccessTaxation = true;
          this.getSettings();
        })
        .catch(() => {
          this.taxationSaveLoading = false;
          this.snackErrorTaxation = true;
        });
    },

    saveDividends() {
      this.dividendsSaveLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          dividends: this.dividends,
        })
        .then(() => {
          this.dividendsSaveLoading = false;
          this.snackSuccessDividends = true;
          this.getSettings();
        })
        .catch(() => {
          this.dividendsSaveLoading = false;
          this.snackErrorDividends = true;
        });
    },
  },
};
</script>
