var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"end"}},[_c('h3',{staticClass:"title text-capitalize"},[_vm._v(_vm._s(_vm.$t("accounting settings")))])])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":"","sm6":"","md6":"","lg4":""}},[_c('v-card',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-1"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-scales")]),_vm._v(_vm._s(_vm.$t("taxation"))+"(%) ")],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("set your Taxation rate on earnings"))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","rules":_vm.rules.emptyField},on:{"keypress":_vm.digits},model:{value:(_vm.taxation),callback:function ($$v) {_vm.taxation=$$v},expression:"taxation"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("if not set, it will be calculated at 0%")))])])],1),_c('v-btn',{staticClass:"gradients",attrs:{"small":"","dark":"","color":_vm.$store.state.secondaryColor,"loading":_vm.taxationSaveLoading},on:{"click":_vm.savePaybill}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("save")]),_c('span',{staticClass:"caption text-capitalize font-weight-bold"},[_vm._v(_vm._s(_vm.$t("save")))])],1)],1)],1)],1)],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":"","sm6":"","md6":"","lg4":""}},[_c('v-card',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-1"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-scales")]),_vm._v(_vm._s(_vm.$t("dividends"))+"(%) ")],1),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$t("set your dividends rate on earnings"))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","rules":_vm.rules.emptyField},on:{"keypress":_vm.digits},model:{value:(_vm.dividends),callback:function ($$v) {_vm.dividends=$$v},expression:"dividends"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("if not set, it will be calculated at 0%")))])])],1),_c('v-btn',{staticClass:"gradients",attrs:{"small":"","dark":"","color":_vm.$store.state.secondaryColor,"loading":_vm.dividendsSaveLoading},on:{"click":_vm.saveDividends}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("save")]),_c('span',{staticClass:"caption text-capitalize font-weight-bold"},[_vm._v(_vm._s(_vm.$t("save")))])],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"success lighten-5 success--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccessTaxation = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccessTaxation),callback:function ($$v) {_vm.snackSuccessTaxation=$$v},expression:"snackSuccessTaxation"}},[_c('span',[_vm._v(_vm._s(_vm.$t("taxation percentage number updated")))])]),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"red lighten-5 error--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackErrorTaxation = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackErrorTaxation),callback:function ($$v) {_vm.snackErrorTaxation=$$v},expression:"snackErrorTaxation"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not update taxation percentage. Try again later")))])]),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"success lighten-5 success--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccessDividends = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccessDividends),callback:function ($$v) {_vm.snackSuccessDividends=$$v},expression:"snackSuccessDividends"}},[_c('span',[_vm._v(_vm._s(_vm.$t("dividends percentage number updated")))])]),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"red lighten-5 error--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackErrorDividends = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackErrorDividends),callback:function ($$v) {_vm.snackErrorDividends=$$v},expression:"snackErrorDividends"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not update dividends percentage. Try again later")))])]),_c('v-snackbar',{attrs:{"top":"","centered":"","color":"red lighten-5 error--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackErrorLoading = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackErrorLoading),callback:function ($$v) {_vm.snackErrorLoading=$$v},expression:"snackErrorLoading"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not fetch data. Try again later")))])]),_c('v-snackbar',{attrs:{"top":"","center":"","color":"info lighten-5 info--text text--darken-3","timeout":12000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"info darken-3"},on:{"click":function($event){_vm.snackLoading = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackLoading),callback:function ($$v) {_vm.snackLoading=$$v},expression:"snackLoading"}},[_c('span',[_vm._v(_vm._s(_vm.$t("loading ...")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }